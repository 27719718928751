import React, { useEffect, useState, useRef } from 'react';
import { GetOrderList, GetAllTags } from '../../actions/orderActions';
import { StyleSheet, View, Animated } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import OrderSearchList from './order_search_list';
import { LinearGradient } from 'expo-linear-gradient';

function Spinner() {
  const rotation = useRef(new Animated.Value(0)).current;

  const animate = () => {
    Animated.timing(rotation, {
      toValue: 1,
      duration: 3000,
      useNativeDriver: true,
    })?.start(() => {
      rotation.setValue(0);
      animate();
    });
  };

  useEffect(() => {
    animate();

    return () => {
      rotation.stopAnimation();
    };
  }, []);

  const spin = rotation.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  return (
    <View style={styles.spinnerContainer}>
      <Animated.View style={[styles.single4, { transform: [{ rotate: spin }] }]}></Animated.View>
    </View>
  );
}

function AllOrders(props) {
  const {
    navigation
  } = props;
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState(null);
  const dispatch = useDispatch();
  const ordersList = useSelector(state => state.order.list);
  const userData = useSelector(state => state.user.userInfo);
  const data = {
    filter: 'all',
    order: 'DESC',
    limit: 20,
    offset: '0',
    app: '',
    product_search_toggle: undefined
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await hitApi();
    };
    fetchData();
  }, []);

  const hitApi = async () => {
    apiCall();
  };

  const apiCall = () => {
    dispatch(GetOrderList(data));
    dispatch(GetAllTags());
  };

  useEffect(() => {
    setOrders(prevOrders => {
      if (ordersList && prevOrders !== ordersList?.orders) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        return ordersList?.orders || [];
      }
      return prevOrders;
    });
  }, [ordersList]);

  return (
    <LinearGradient
      colors={[
        '#000',
        '#000',
        '#5c778f',
        '#5c778f',
        '#253c57',
        '#253c57',
        '#000'
      ]}
      start={[-0.5, -1]}
      style={{
        flex: 1,
        paddingBottom: 15
      }}>
      {loading ? <Spinner testID="spinner" /> : (
        <OrderSearchList
          {...props}
          productSearchList={orders}
          navigation={navigation}
          userInfo={userData}
          initial_status={'Awaiting'}
          orderSearchBack={() => { }}
          onComeBack={() => { }}
          orderCount={ordersList?.orders_count}
        />
      )}
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  spinnerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  single4: {
    width: 50,
    height: 50,
    borderRadius: 25,
    borderWidth: 6,
    borderColor: '#BFBFBF',
    borderStyle: 'solid',
    borderLeftColor: '#EEEEEE',
    borderRightColor: '#EEEEEE',
    borderTopColor: '#BFBFBF',
    borderBottomColor: '#BFBFBF',
    transform: 'rotate(0deg)',
    animationDuration: 2500,
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
  }
});

export default AllOrders;
