import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Text,
  View,
  Dimensions,
  Image,
  TouchableOpacity,
  ScrollView
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import {connect} from 'react-redux';
import {UserInfo} from '../actions/userAction';
import {GetBothSettings} from '../actions/userAction';
import {SetOrdersUpdated, GetOrderList} from '../actions/orderActions';

import SignIn from './login/signin';
import OrderList from './orders/orderlist';
import OrderDetail from './orders/orderdetail';
import ItemsDetail from './orders/items_detail';
import AddItemToOrder from './orders/add_item_to_order';
import OrderSearch from './orders/order_search';
import Settings from './directPrinting/settings';
import InventoryKitOptions from './products/inventory_kit_options';
import ScanPack from './scanpack/scanpack';
import ScanPackItem from './scanpack/scanpack_item';
import ProductEdit from './scanpack/product_edit';
import ProductDetail from './products/product_detail';
import ProductSearch from './products/product_search';
import PutWall from './putwall/put_wall';
import Drawer from './drawer/drawer';
import {
  ScanReceive,
  ScanRecount,
  ProductRecount,
  ProductReceive,
  CountReceive,
  CountRecount,
  ProductListReceive,
  ProductListRecount
} from './receive_recount';
import SyncButton from './actionButton/sync';
import toggle from '../../assets/toggle1.png';
import {navigationRef} from './root_navigation';
import Close from '../../assets/closeicon.png';
import {LinearGradient} from 'expo-linear-gradient';
import {fontFamily} from '../helpers/fontFamily';
import * as RootNavigation from './root_navigation';
import inventory_kit_options from './products/inventory_kit_options';
import PackingCam from './scanpack/packing_cam';
import {CheckIsMenuOpen, MountComponent} from '../actions/menuActions';
import ShippingLabel from './orders/shipping_label';
import CustomHeader from '../commons/customHeader';
import PrinterShow from './directPrinting/printerShow';
import printJob from './printJob';
import AllOrders from './orders/all_orders';
import { fetchCompletedOrdersLocally, updateOrdersFlag } from '../DexieDbUtils';
import InterNetCheck from './internateConnectionCheck';
import { detectOrdersDiscrepancies } from '../actions/scanpackAction';

const TIMER_MS = 20000; // 20 seconds
const DATA = {
  filter: 'all',
  order: 'DESC',
  limit: 20,
  offset: '0',
  app: '',
  product_search_toggle: undefined
}
const Stack = createStackNavigator();

function Main(props) {
  const disPatch = useDispatch();
  const [token, setToken] = useState('');
  const [show, setShow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get('window').width
  );
  let [navShow, setNavShow] = useState(true);
  let [openDrawer, handelDrawer] = useState(false);
  let [restartFlag, restartHandel] = useState(false);
  let [alertShow, alertShowHandle] = useState(false);
  const { mountComponent} = useSelector(state => state.user);
  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  useEffect(() => {
    props.socketFun();
  }, []);

  useEffect(() => {
    const asyncEffect = async () => {
      if(props.progressCount >= 100 && props.updateOrders){
        disPatch(SetOrdersUpdated());
        props.restProgress();
      }
    };

    asyncEffect();
  }, [props]);

  AsyncStorage.getItem('access_token').then(keyValue => {
    setToken(keyValue);
    setShow(true);
    return keyValue;
  });

  props.getData(mountComponent)

  function DrawerButton({size}) {
    let fontSize = size ? (windowWidth < 800 ? size : '40px') : '40px';
    return (
      <TouchableOpacity
        onPress={() => {
          handelDrawer((openDrawer = true));
          disPatch(CheckIsMenuOpen(true));
        }}
        style={{
          flex: 1,
          marginLeft: 20,
          justifyContent: 'center',
          width: '100%'
        }}>
        <i
          className="icon-menu"
          style={{color: 'white', fontSize: fontSize, marginLeft: -10}}></i>
        {/* <Image
          style={{
            width: 39,
            height: 31,
            color: "#fff",
            resizeMode: "contain",
          }}
          source={toggle}
        /> */}
      </TouchableOpacity>
    );
  }

  function closeDrawer() {
    handelDrawer((openDrawer = false));
    0;
    disPatch(CheckIsMenuOpen(false));
  }

  function navBarShow(position) {
    setNavShow((navShow = position));
  }

  function restartButton() {
    restartHandel((restartFlag = !restartFlag));
  }

  function changeHeading(num) {
    if (num === 0) {
      name = 'Scanned Item';
    }
    if (num === 1) {
      name = 'Next Item';
    }
    if (num === 2) {
      name = 'Unscanned Item';
    }
  }

  function syncButtonHandle() {
    alertShowHandle(true);
  }
  function checkFunctionHere() {
    disPatch(MountComponent(!mountComponent))
  }

  useEffect(async () => {
    const interval = setInterval(async () => {
      let token = await AsyncStorage.getItem('access_token');
      let info = await AsyncStorage.getItem('userInfo');
      let userInfo = info && JSON.parse(info);
      let {id, current_tenant} = userInfo || {};
      let popInternet = await InterNetCheck();

      // return if user logged out
      if (!token || !popInternet || !id) return;

      let orders = await fetchCompletedOrdersLocally(id, current_tenant);
      if (orders.length > 0) {
        // 30 seconds
        const thirtySecondsInMilliseconds = 30 * 1000;

        // Filter orders created 30 seconds before
        const filteredOrders = orders.filter(order => {
          let currentTime = new Date();
          const updatedAtTime = new Date(order.updated_at);

          // Time difference in milliseconds
          const timeDifference = currentTime - updatedAtTime;
          return timeDifference >= thirtySecondsInMilliseconds;
        });

        if (filteredOrders.length > 0) {
          const mappedOrders = filteredOrders.map(order => ({ tenant: current_tenant, user_id: id, order_id: order.order_id, status: order.flag }));
          disPatch(detectOrdersDiscrepancies(mappedOrders));
        }
      }
    }, TIMER_MS);

    return () => {
      clearInterval(interval);
    }
  }, [])

  useEffect(() => {
    const handleOrdersData = async () => {
      let {result} = props.ordersData || {};
      // return if there's no item in result
      if (result?.length <= 0) return;

      result.forEach(async item => {
        let {tenant, user_id, order_id, status, discrepancy} = item || {};
        if (discrepancy) {
          await updateOrdersFlag(tenant, user_id, order_id, {flag: 'reported'})
        } else {
          await updateOrdersFlag(tenant, user_id, order_id, {flag: 1})
        }
      });
    }

    if (props.ordersData) {
      handleOrdersData();
    }
  }, [props.ordersData])

  return (
    show && (
      <React.Fragment>
        <NavigationContainer ref={navigationRef} style={{zIndex: -1}}>
          <Stack.Navigator
            initialRouteName={token ? 'ScanPack' : 'SignIn'}
            style={{zIndex: -1}}>
            <Stack.Screen
              name="SignIn"
              component={SignIn}
              initialParams={{
                closeDrawer: () => {
                  closeDrawer();
                },
                currentSocket: props.currentSocket
                // socketFun : ()=>{props.socketFun()}
              }}
              options={{headerShown: false}}
            />
            <Stack.Screen
              name="OrderList"
              component={OrderList}
              path="/123"
              options={{
                title: 'List',
                headerStyle: {
                  backgroundColor: '#336599'
                },
                headerTintColor: '#fff',
                headerTitleStyle: {
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: 22
                },
                headerLeft: null,
                headerLeft: () => <DrawerButton />
              }}
            />
            <Stack.Screen
              name="OrderDetail"
              component={OrderDetail}
              initialParams={{
                closeDrawer: () => {
                  closeDrawer();
                }
              }}
              options={{
                header: () => (
                  <CustomHeader
                    title="Order Detail"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    showSync={false}
                  />
                )
              }}
            />
            <Stack.Screen
              name="ShippingLabel"
              component={ShippingLabel}
              initialParams={{
                closeDrawer: () => {
                  closeDrawer();
                }
              }}
              options={{
                header: () => (
                  <CustomHeader
                    title="Shipping Label"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    showSync={false}
                  />
                )
              }}
            />

            <Stack.Screen
              name="ScanPack"
              component={ScanPack}
              initialParams={{
                closeDrawer: () => {
                  closeDrawer();
                },
                socketFun: () => {
                  props.socketFun();
                },
                modalVisible:props.modalVisible
                // modalVisible: () => {
                //   props.modalVisible;
                // }
              }}
              options={{
                header: () => (
                  <CustomHeader
                    title="Scan & Verify"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    showSync={true}
                  />
                )
              }}
            />
            <Stack.Screen
              name="ScanPackItem"
              component={ScanPackItem}
              initialParams={{
                item: navBarShow,
                restart: restartFlag,
                restartButton: restartButton,
                changeHeading: changeHeading,
                closeDrawer: () => {
                  closeDrawer();
                },
                socketFun: () => {
                  props.socketFun();
                },
                handlePrint: urlcheck => {
                  props.handlePrint(urlcheck);
                }
              }}
              options={{
                header: () => (
                  <CustomHeader
                    title="Scan & Verify"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    height={windowWidth > 900 ? 64 : 40}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    isTouchableTitle={true}
                    onPressTitle={() => RootNavigation.navigate('ScanPack')}
                    onClick={() => alertShowHandle(false)}
                    Close={Close}
                    showSync={false}
                  />
                )
              }}
            />
            <Stack.Screen
              name="ProductSearch"
              component={ProductSearch}
              initialParams={{
                item: navBarShow,
                restart: restartFlag,
                restartButton: restartButton,
                changeHeading: changeHeading,
                closeDrawer: () => {
                  closeDrawer();
                },
                userInfo: props.userInfo
              }}
              options={{
                header: () => (
                  <CustomHeader
                    title="Product Search"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    isTouchableTitle={true}
                    onPressTitle={() => RootNavigation.navigate('ScanPack')}
                    onClick={() => alertShowHandle(false)}
                    Close={Close}
                    showSync={true}
                  />
                )
              }}
            />
            <Stack.Screen
              name="OrderSearch"
              component={OrderSearch}
              initialParams={{
                item: navBarShow,
                restart: restartFlag,
                restartButton: restartButton,
                changeHeading: changeHeading,
                closeDrawer: () => {
                  closeDrawer();
                },
                userInfo: props.userInfo
              }}
              options={{
                header: () => (
                  <CustomHeader
                    title="Order List"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    isTouchableTitle={true}
                    onPressTitle={() => RootNavigation.navigate('ScanPack')}
                    onClick={() => alertShowHandle(false)}
                    Close={Close}
                    showSync={true}
                    progressCount={props.progressCount}
                  />
                )
              }}
            />
            <Stack.Screen
              name="Settings"
              component={Settings}
              initialParams={{
                item: navBarShow,
                restart: restartFlag,
                restartButton: restartButton,
                changeHeading: changeHeading,
                closeDrawer: () => {
                  closeDrawer();
                },
                GetBothSettings: props.GetBothSettings,
                userInfo: props.userInfo
              }}
              options={{
                header: () => (
                  <CustomHeader
                    title="Settings"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));           
                    }}                  
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    isTouchableTitle={true}
                    onPressTitle={() => {
                      disPatch(MountComponent(!mountComponent))
                      RootNavigation.navigate('ScanPack')}
                    }
                    onClick={() => alertShowHandle(false)}
                    Close={Close}
                    showSync={true}                    
                  />
                )
              }}
            />
            <Stack.Screen
              name="ProductEdit"
              component={ProductEdit}
              options={{
                title: 'Edit Product',
                headerStyle: {
                  backgroundColor: '#336599'
                },
                headerTintColor: '#fff',
                headerTitleStyle: {
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: 22
                }
              }}
            />
            <Stack.Screen
              name="ProductDetail"
              component={ProductDetail}
              initialParams={{
                closeDrawer: () => {
                  closeDrawer();
                },
                socketFun: (arg) => {
                  props.socketFun(arg);
                },
                
              }}
              options={{
                header: () => (
                  <CustomHeader
                    title="Product Detail"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    showSync={false}
                  />
                )
              }}
            />
            <Stack.Screen
              name="ItemsDetail"
              component={ItemsDetail}
              options={{
                title: 'Items Detail',
                headerStyle: {
                  backgroundColor: '#336599'
                },
                headerTintColor: '#fff',
                headerTitleStyle: {
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: 22
                }
              }}
            />
            <Stack.Screen
              name="PutWall"
              component={PutWall}
              options={{
                headerStyle: {
                  backgroundColor: '#336599'
                },
                headerTintColor: '#fff',
                headerTitleStyle: {
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: 22
                }
              }}
            />
            <Stack.Screen
              name="Select Product to add to Order"
              component={AddItemToOrder}
              options={{
                headerStyle: {
                  backgroundColor: '#336599'
                },
                headerTintColor: '#fff',
                headerTitleStyle: {
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: 22
                }
              }}
            />
            <Stack.Screen
              name="PackingCam"
              component={PackingCam}
              options={{
                header: () => (
                  <CustomHeader
                    title="Packing Cam"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    showSync={false}
                  />
                )
              }}
            />
            <Stack.Screen
              name="ScanRecount"
              component={ScanRecount}
              options={{
                header: () => (
                  <CustomHeader
                    title="Recount Inventory"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    showSync={false}
                  />
                )
              }}
            />
            <Stack.Screen
              name="ScanReceive"
              component={ScanReceive}
              options={{
                header: () => (
                  <CustomHeader
                    title="Receive Inventory"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    showSync={false}
                  />
                )
              }}
            />
            <Stack.Screen
              name="PrintJob"
              component={printJob}
              options={{
                header: () => (
                  <CustomHeader
                    title="Print Jobs"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    showSync={false}
                  />
                )
              }}
            />
            <Stack.Screen
              name="Orders"
              component={AllOrders}
              initialParams={{
                socketFun: () => {
                  props.socketFun();
                },
              }}
              options={{
                header: () => (
                  <CustomHeader
                    title="Orders"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    showSync={false}
                    progressCount={props.progressCount}
                  />
                )
              }}
            />
            <Stack.Screen
              name="ProductReceive"
              component={ProductReceive}
              options={{
                header: () => (
                  <CustomHeader
                    title="Receive Inventory"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    showSync={false}
                  />
                )
              }}
            />
            <Stack.Screen
              name="ProductRecount"
              component={ProductRecount}
              options={{
                header: () => (
                  <CustomHeader
                    title="Recount Inventory"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    showSync={false}
                  />
                )
              }}
            />
            <Stack.Screen
              name="CountReceive"
              component={CountReceive}
              options={{
                header: () => (
                  <CustomHeader
                    title="Receive Inventory"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    showSync={false}
                  />
                )
              }}
            />
            <Stack.Screen
              name="CountRecount"
              component={CountRecount}
              options={{
                header: () => (
                  <CustomHeader
                    title="Recount Inventory"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    showSync={false}
                  />
                )
              }}
            />
            <Stack.Screen
              name="ProductListReceive"
              component={ProductListReceive}
              options={{
                header: () => (
                  <CustomHeader
                    title="Receive Inventory"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    showSync={false}
                  />
                )
              }}
            />
            <Stack.Screen
              name="ProductListRecount"
              component={ProductListRecount}
              options={{
                header: () => (
                  <CustomHeader
                    title="Recount Inventory"
                    username={props.userInfo && props.userInfo.username}
                    tenant={props.userInfo && props.userInfo.current_tenant}
                    onPress={() => {
                      handelDrawer((openDrawer = true));
                      disPatch(CheckIsMenuOpen(true));
                    }}
                    syncButtonHandle={() => syncButtonHandle()}
                    alertShow={alertShow}
                    windowWidth={windowWidth}
                    showSync={false}
                  />
                )
              }}
            />
          </Stack.Navigator>
        </NavigationContainer>
        {openDrawer && <Drawer 
        closeDrawer={closeDrawer} 
        checkFunctionHere = {checkFunctionHere}
        />}
      </React.Fragment>
    )
  );
}

const mapStateToProps = state => {
  return {
    userInfo: state.user.userInfo,
    ordersData: state.scanpack.orders_data,
    ordersUpdated: state.order.ordersUpdated
  };
};

const mapDispatchToProps = {
  UserInfo
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
