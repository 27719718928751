import React, { useState, useEffect, useRef } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
  TextInput
} from 'react-native';
import ListItem from './listItem';
import { LinearGradient } from 'expo-linear-gradient';
import { filterDataWithGroovepacker, filterDataWithoutGroovepacker } from '../../../components/orders/commonFunctions';
import CustomScrollBar from '../../customScrollBar';

const AddEditDropDown = ({ options, resetOpenEditDropdown }) => {
  console.log("INTI>>< ", options)
  const [isOpen, setIsOpen] = useState(true);
  const [editingItem, setEditingItem] = useState();
  const [isPickerModalVisible, setIsPickerModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef(null);

  const [tags, setTags] = useState(() => {
    const data = options;
    console.log("DATE>>>> ", filterDataWithoutGroovepacker(data))

    return filterDataWithoutGroovepacker(data);
  });

  const [tag2, setTag2] = useState(() => {
    const data = options;
    return filterDataWithGroovepacker(data);
  });

  const handleEditTag = updatedTag => {
    setTags(prevTags =>
      prevTags.map(tag => (tag.id === updatedTag.id ? updatedTag : tag))
    );
    setTag2(prevTag2 =>
      prevTag2.map(tag => (tag.id === updatedTag.id ? updatedTag : tag))
    );
  };

  const addTag = () => {
    const now = new Date();
    const timestamp = now.getTime();
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    const randomInt = array[0] % 1000;    const newTag = {
      id: randomInt,
      name: '',
      color: '#808080',
      isVisible: true
    };
    setTag2(prevTag2 => [newTag, ...prevTag2]);
    setEditingItem(newTag.id);
  };

  const filteredTags = tags.filter(tag =>
    tag.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredTag2 = tag2.filter(tag =>
    tag.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        !isPickerModalVisible && setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPickerModalVisible]);

  return (
    <View style={styles.container} ref={dropdownRef}>
      <TouchableOpacity testID='editTagsBtn' onPress={() => setIsOpen(!isOpen)}>
        <View style={styles.header}>
          <Text style={styles.headerText}>Edit Tags</Text>
          <i
            className={`${!isOpen ? 'icon-down-open' : 'icon-up-open'}`}
            style={{ color: '#7A7A7A', fontSize: 16, marginTop: 1 }}></i>
        </View>
      </TouchableOpacity>
      {isOpen && (
        <LinearGradient
          colors={['#262B30', '#262B30']}
          style={{
            position: 'absolute',
            width: 'max-content',
            minWidth: '9vw',
            top: 20,
            height: '22rem',
            borderRadius: 10
          }}>
          <View style={styles.searchContainer}>
            <TouchableOpacity style={styles.searchIconContainer} onPress={resetOpenEditDropdown}>
              <i className="icon-left-open" style={styles.icon}></i>
            </TouchableOpacity>
            <TextInput
              autoFocus
              value={searchQuery}
              onChangeText={setSearchQuery}
              placeholder="Type Tags to search"
              style={styles.searchInput}
              onSubmitEditing={() => { }} // Add this to handle "Enter" key press if needed
            />
            {searchQuery && <TouchableOpacity
              testID='closeSearch'
              style={{ marginLeft: '-10%', marginTop: '3px' }}
              onPress={() => {
                setSearchQuery("");
              }}
            >
              <i
                className="icon-cancel-circled"
                style={{ fontSize: 17, color: 'gray' }}
              ></i>
            </TouchableOpacity>}
            <TouchableOpacity style={styles.searchIconContainer}>
              <i className="icon-search" style={styles.icon}></i>
            </TouchableOpacity>
          </View>
          <TouchableOpacity testID='AddTagBtn' style={styles.addTagContainer} onPress={addTag}>
            <i className="icon-plus" style={styles.icon}></i>
            <Text style={styles.addTagText}>add a tag</Text>
          </TouchableOpacity>
          <CustomScrollBar>
            <View style={[styles.content, styles.separator]}>
              {filteredTag2.map(item => (
                <ListItem
                  key={item.id}
                  item={item}
                  setEditingItemId={value => {
                    setEditingItem(value);
                  }}
                  editingItemId={editingItem}
                  onEdit={handleEditTag}
                  setIsPickerModalVisible={(value) => {setIsPickerModalVisible(value)}}
                />
              ))}
            </View>
            <View style={styles.content}>
              {filteredTags.map(item => (
                <ListItem
                  key={item.id}
                  item={item}
                  setEditingItemId={value => {
                    setEditingItem(value);
                  }}
                  editingItemId={editingItem}
                  onEdit={handleEditTag}
                  setIsPickerModalVisible={(value) => {setIsPickerModalVisible(value)}}
                />
              ))}
            </View>
          </CustomScrollBar>
        </LinearGradient>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '10vw',
    position: 'relative',
    marginLeft: 4
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerText: {
    color: '#7A7A7A',
    fontWeight: 'bold',
    fontSize: 14,
    fontFamily: 'Poppins_600SemiBold'
  },
  icon: {
    fontSize: 16,
    color: 'white'
  },
  dropdown: {
    maxHeight: '60vh'
  },
  content: {
    padding: 10
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5
  },
  item: {
    color: 'white',
    fontFamily: 'Poppins_300Light',
    paddingVertical: 5
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    padding: 5
  },
  searchInput: {
    backgroundColor: '#C7C7C7',
    borderRadius: 5,
    width: '80%',
    padding: 5,
    height: 22,
    marginTop: 2,
    fontFamily: 'Poppins_300Light'
  },
  searchIconContainer: {
    padding: 5
  },
  addTagContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    backgroundColor: '#1A1D20'
  },
  addTagText: {
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Poppins_600SemiBold',
    color: 'white',
  },
  separator: {
    borderBottomWidth: 1,
    borderColor: 'gray'
  }
});

export default AddEditDropDown;
