import React, { useState, useRef, useEffect } from 'react';
import { Pressable, TouchableOpacity, StyleSheet, Text, View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {FontAwesome} from '@expo/vector-icons';
import _ from 'lodash';


function MyCheckbox({ status, tagName, getStatus, prevlimit, prevSkip, filters, search, gridSelectionRef, selectedStates, loadTagsData, tagsStatus, setOrdersTagsStatus, updateOrdersTagsStatus, unselectedOrderItems }) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (status === 'all_present') {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [status]);


  const renderIcon = () => {
    if (status === 'all_present') {
      return <FontAwesome name="check" size={16} color="white" style={{marginRight: -4, fontWeight: 900}}/>;
    } else if (status === 'partially_present') {
      return <FontAwesome name="minus-square" size={15} color="white" style={{marginRight: -1}} />;
    }
    return null;
  };

  const addOrRemovefunction = async() => {
    let updatedURL;
    let select_all = false;
    if(gridSelectionRef.selected === true) select_all = true;

    let gridSelectionIdsArray = Object.values(gridSelectionRef.selected || {}).map(item => ({ id: item.currentItem.id })) 
    gridSelectionIdsArray = gridSelectionIdsArray.length === 0 ? Object.values(gridSelectionRef?.data || {}).map(item => ({ id: item?.currentItem?.id })) : gridSelectionIdsArray
    const orders_id = encodeURIComponent(JSON.stringify(gridSelectionIdsArray));
    const url = await AsyncStorage.getItem('url');

    if (status === 'all_present' || status === 'partially_present') {
      updatedURL = `${url}/orders/remove_tags.json?filter=${getStatus}&order=DESC&limit=${prevlimit}&sort=&offset=${prevSkip}&app=&count=&filters=${filters}&search=${(search || "")}&tag_name=${tagName}&select_all=${select_all}&orderArray=${orders_id}&unselected=${unselectedOrderItems || ""}`;
    } else if (status === 'not_present' || !status) {
      updatedURL = `${url}/orders/add_tags.json?filter=${getStatus}&order=DESC&limit=${prevlimit}&sort=&offset=${prevSkip}&app=&count=&filters=${filters}&search=${(search || "")}&tag_name=${tagName}&select_all=${select_all}&orderArray=${orders_id}&unselected=${unselectedOrderItems || ""}`;
    }
    postData(updatedURL, status)
  };


  const postData = async(url, action) => {
    const access_token = await AsyncStorage.getItem('access_token');
    if (url !== "") {
      try {
        const response = await axios({
          method: 'post',
          url: url,
          headers: {Authorization: `Bearer ${access_token}`, 'content-type': 'application/json'},
          
        });
        if(response.status){
          updateOrdersTagsStatus(status, tagName);
        }
      } catch (error) {
       console.log(">>>>>>>>>.", error);
      }
    }
  }

  return (
    <TouchableOpacity
      testID='addOrRemovefunction'
      style={[styles.checkboxBase, checked && styles.checkboxChecked]}
      onPress={() => {
        addOrRemovefunction();
      }}>
      {renderIcon()}
    </TouchableOpacity>
  );
}

export default function CustomCheckBox({ option, ordersTagsStatus, getOrdersStatus, prevlimit, prevSkip, filters, search, gridSelectionRef, selectedStates, loadTagsData, updateOrdersTagsStatus, unselectedOrderItems }) {
  const [tagsStatus, setTagsStatus] = useState(ordersTagsStatus);

  useEffect(() => {
      setTagsStatus(ordersTagsStatus);
  }, [ordersTagsStatus]);
  
  const getStatus = (tagName) => {
    if (tagsStatus?.all_present.includes(tagName)) {
      return 'all_present';
    } else if (tagsStatus?.partially_present.includes(tagName)) {
      return 'partially_present';
    } else if (tagsStatus?.not_present.includes(tagName)) {
      return 'not_present';
    }
    return null;
  };

    return (
      <View style={styles.appContainer}>
        <View style={styles.checkboxContainer}>
          <MyCheckbox 
          status={option && getStatus(option?.name)} 
          tagName={option?.name}
          getStatus = {getOrdersStatus}
          prevlimit= {prevlimit}
          prevSkip = {prevSkip}
          filters = {filters}
          search = {search}
          gridSelectionRef = {gridSelectionRef}
          selectedStates = {selectedStates}
          loadTagsData ={loadTagsData}
          tagsStatus ={tagsStatus}
          setOrdersTagsStatus = {(text) => setTagsStatus(text)}
          updateOrdersTagsStatus = {updateOrdersTagsStatus}
          unselectedOrderItems = {unselectedOrderItems}
          />
          {/* <Text style={styles.checkboxLabel}>{`⬅️ Click!`}</Text> */}
        </View>
      </View>
    );
  }

const styles = StyleSheet.create({
  checkboxBase: {
    width: 15,
    height: 15,
    justifyContent: 'center',
    alignItems: 'end',
    borderRadius: 4,
    borderWidth: 2,
    borderColor: 'white',
    backgroundColor: 'transparent',
    right: '70%'
  },
  checkboxChecked: {
    backgroundColor: 'none',
  },
  appContainer: {
    flex: 1,
    alignItems: 'end',
    justifyContent: 'center',
  },
  appTitle: {
    marginVertical: 16,
    fontWeight: 'bold',
    fontSize: 24,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxLabel: {
    marginLeft: 8,
    fontWeight: '500',
    fontSize: 18,
  }
});
